/**
 * The DR&AM environment of the app
 */
export const environment = {
  production: true,
  title: 'Snag list',
  color: '#1976d2',
  settings: {
    forceLogin: true,
    account: {
      hasCreateFunctionality: false,
      hasReadFunctionality: true,
      hasUpdateFunctionality: false,
      hasDeleteFunctionality: false,
    },
    project: {
      hasCreateFunctionality: false,
      hasReadFunctionality: true,
      hasUpdateFunctionality: false,
      hasDeleteFunctionality: false,
    },
    shipyard: {
      hasCreateFunctionality: false,
      hasReadFunctionality: true,
      hasUpdateFunctionality: false,
      hasDeleteFunctionality: false,
    },
    boat: {
      hasCreateFunctionality: false,
      hasReadFunctionality: true,
      hasUpdateFunctionality: false,
      hasDeleteFunctionality: false,
    },
    section: {
      asDecks: false,
      hasCreateFunctionality: true,
      hasReadFunctionality: true,
      hasUpdateFunctionality: true,
      hasDeleteFunctionality: true,
    },
    task: {
      filterStatuses: [
        {
          status_id: 'submitted',
          name: 'Sent'
        },
        {
          status_id: 'denied',
          name: 'Declined'
        },
        {
          status_id: 'accepted',
          name: 'Accepted'
        },
        {
          status_id: 'in progress',
          name: 'In progress'
        },
        {
          status_id: 'completed',
          name: 'Completed'
        },
        // {
        //   status_id: 'monitored',
        //   name: 'Monitored'
        // }
      ],
      flowStatuses: [
        // Primary
        {
          key: 'draft',
          label: 'Draft',
          nextKeys: ['draft', 'submitted'],
          previousKeys: []
        },
        {
          key: 'submitted',
          label: 'Sent',
          nextKeys: ['submitted', 'accepted', 'denied'],
          previousKeys: ['draft']
        },
        {
          key: 'accepted',
          label: 'Accepted',
          nextKeys: ['accepted', 'in progress'],
          previousKeys: ['submitted']
        },
        {
          key: 'denied',
          label: 'Declined',
          nextKeys: ['denied'],
          previousKeys: ['submitted']
        },
        {
          key: 'in progress',
          label: 'In progress',
          nextKeys: ['in progress', 'completed'],
          previousKeys: ['accepted', 'monitored']
        },
        {
          key: 'completed',
          label: 'Completed',
          nextKeys: ['completed', 'in progress', 'monitored'],
          previousKeys: ['in progress']
        },
        {
          key: 'monitored',
          label: 'Monitored',
          nextKeys: ['monitored', 'in progress'],
          previousKeys: ['completed']
        },
        // Remark
        {
          key: 'open',
          label: 'Open',
          nextKeys: ['open', 'no_actions', 'local_actions', 'total_actions'],
          previousKeys: []
        },
        {
          key: 'no_actions',
          label: 'No actions',
          nextKeys: ['no_actions', 'closed-no_actions'],
          previousKeys: []
        },
        {
          key: 'closed-no_actions',
          label: 'Closed remark',
          nextKeys: [],
          previousKeys: []
        },
        {
          key: 'local_actions',
          label: 'Local actions',
          nextKeys: ['local_actions', 'closed-local_actions'],
          previousKeys: []
        },
        {
          key: 'closed-local_actions',
          label: 'Closed remark',
          nextKeys: [],
          previousKeys: []
        },
        {
          key: 'total_actions',
          label: 'Total actions',
          nextKeys: ['total_actions', 'closed-total_actions'],
          previousKeys: []
        },
        {
          key: 'closed-total_actions',
          label: 'Closed remark',
          nextKeys: [],
          previousKeys: []
        },
      ],
      hasCreateFunctionality: false,
      hasOfflineDraftsFuncionality: false,
      hasPreviuosStatusFuncionality: false,
      create: {
        useSuggestions: true,
        canChangeStatus: false,
        showZone: false,
        canSetZone: false,
        canChangeZone: false,
        canChangeType: true,
        canViewDoc: false,
        showPrivacy: false,
        canChangePrivacy: false,
        canChangeLocation: false,
        showResolutionDate: false,
        canSetResolutionDate: false,
        canChangeResolutionDate: false,
        showResolutor: false,
        canSetResolutor: false,
        canChangeResolutor: false,
        showDocumentReference: false,
        canSetDocumentReference: false,
        canChangeDocumentReference: false
      },
      edit: {
        useSuggestions: true,
        canChangeStatus: true,
        showZone: false,
        canSetZone: false,
        canChangeZone: false,
        canChangeType: false,
        canViewDoc: false,
        showPrivacy: false,
        canChangePrivacy: false,
        canChangeLocation: false,
        canBeDeleted: true,
        showResolutionDate: true,
        canSetResolutionDate: true,
        canChangeResolutionDate: true,
        showResolutor: true,
        canSetResolutor: true,
        canChangeResolutor: true,
        showDocumentReference: true,
        canSetDocumentReference: true,
        canChangeDocumentReference: true
      }
    },
    report: {
      listOfPoints: {
        csvType: 'dream'
      }
    },
    hasUpdatesFunctionality: false,
    hasYachtFilterFunctionality: false,
    hasYachtHistoryFunctionality: false,
    hasYachtInfoFunctionality: false,
    hasAccountsFunctionality: false,
    hasPointFunctionality: true,
    hasEnvironmentFunctionality: false,
    hasZoneFunctionality: false,
    hasRemarkFunctionality: false,
    hasProductFunctionality: false,
    hasApplicationFunctionality: false, // Require zone, remark, product, ...
    hasManagementFunctionality: false,
    hasSurfaceFunctionality: false,
  },
  storage: {
    prefix: 'SL_DEMO_PRD_DREAM__'
  },
  api: {
    client_id: 2,
    client_secret: 'WUAdwDVixJuyTn22FdcDISmXOe9gwvd75LTc1PbF',
    oauth_base_url: 'https://dream-snag-list.demo.staging.netseven.it/oauth/',
    auth_base_url: 'https://dream-snag-list.demo.staging.netseven.it/api/auth/',
    base_url: 'https://dream-snag-list.demo.staging.netseven.it/api/',
    domain: 'https://dream-snag-list.demo.staging.netseven.it/',
    version: 'v1'
  },
  drive: {
    clientId: '',
    apiKey: '',
    appId: '',
    scope: 'https://www.googleapis.com/auth/drive.file',
    parentFolder: 'root'
  }
};

// https://dream.production.netseven.it
// https://dream-snag-list.production.netseven.it
// https://web.dream-snag-list.production.netseven.it